import React, { ReactElement, useEffect } from 'react'
import cn from 'classnames'
import { ToastType, useUI } from '@corratech/pylot-ui/context'
import { Cross, Error, Success, Warning } from '@components/icons'
import s from './Toast.module.scss'
import { useRouter } from 'next/router'
type ToastProps = {
    isToastFixed?: boolean
    isPLP?: boolean
    className?: string
}

const Toast = ({
    isToastFixed,
    isPLP = false,
    className = ''
}: ToastProps): ReactElement => {
    const {
        closeToast,
        toastText,
        displayToast,
        toastType,
        isFixed,
        hideCloseButton
    } = useUI()
    const processedIsToastFixed = isToastFixed ?? isFixed
    const router = useRouter()
    useEffect(() => {
        //scroll to top for toast visibility
        if (displayToast && !processedIsToastFixed) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [displayToast])

    if (
        !isPLP &&
        [
            '/category/[...url_path]',
            '/search',
            '/product/[...product_url]',
            '/bundle/[...bundle_url]'
        ].includes(router.pathname)
    )
        return <></>

    return (
        <div
            className={cn(
                s['toast-container'],
                'toast-container',
                {
                    block: displayToast,
                    hidden: !displayToast,
                    'bg-alert-danger': toastType === ToastType.Danger,
                    'bg-alert-warning': toastType === ToastType.Warning,
                    'bg-alert-success': toastType === ToastType.Success,
                    'is-fixed': processedIsToastFixed
                },
                processedIsToastFixed && s['is-fixed'],
                className
            )}
        >
            <div className={cn(s['toast-content'], 'toast-content')}>
                <div
                    className={cn(
                        s['toast-content-inner'],
                        'toast-content-inner'
                    )}
                >
                    {toastType === ToastType.Success && <Success />}
                    {toastType === ToastType.Danger && <Error />}
                    {toastType === ToastType.Warning && <Warning />}
                    <div className={cn(s['toast-text'], 'toast-text')}>
                        {toastText}
                    </div>
                </div>
                {!hideCloseButton && (
                    <Cross
                        className={cn(s['close-icon'], 'close-icon')}
                        onClick={closeToast}
                        // eslint-disable-next-line i18next/no-literal-string
                        aria-label="close"
                    />
                )}
            </div>
        </div>
    )
}
export default Toast
